import { FiCreditCard } from 'react-icons/fi';

interface PaymentMethodLogo {
  [key: string]: React.ReactNode;
}

export const PAYMENT_METHOD_LOGO: PaymentMethodLogo = {
  card: <FiCreditCard size={33} />,
  banktransfer: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/bank-transfer.svg"
      alt="banktransfer"
    />
  ),
  doku: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/doku_logo.png" alt="doku" />,
  payu: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/payu-logo.png" alt="payu" />,
  paypal: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/pp_cc_mark_111x69.jpg"
      alt="paypal"
    />
  ),
  seerbit: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/seerbit.png" alt="seerbit" />
  ),
  onlinebankingindia: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/online_banking_india.png"
      alt="onlinebankingindia"
    />
  ),
  walletsindia: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/Mobile-payments.png"
      alt="walletsindia"
    />
  ),
  paytm: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/paytm.svg" alt="paytm" />,
  upi: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/upi_india.png" alt="upi india" />
  ),
  alipay: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/alipay_logo.png" alt="alipay" />
  ),
  wechatpay: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/we-chat-pay-logo.png"
      alt="wechatpay"
    />
  ),
  przelewy24: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/Przelewy24_logo.png"
      alt="przelewy24"
    />
  ),
  klarna: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/klarna_logo.png" alt="klarna" />
  ),
  paydollar: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/paydollar.png" alt="paydollar" />
  ),
  seerbitv2: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/seerbitv2_logo.png"
      alt="seerbitv2"
    />
  ),
  mobilemoney: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/Mobile-payments.png"
      alt="mobile-money"
    />
  ),
  fpx: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/FPX-logo.png" alt="fpx" />,
  grabpay: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/grabpay.png" alt="grabpay" />
  ),
  esewa: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/esewa.png" alt="eSewa" />,
  bancontact: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/bancontact-logo.svg"
      alt="bancontact"
    />
  ),
  blik: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/blik_logo.png" alt="blik" />,
  multibanco: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/multibanco_logo.svg"
      alt="multibanco"
    />
  ),
  mobilebanking: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/mobile_banking.svg"
      alt="mobilebanking"
    />
  ),
  netbanking: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/net_banking.svg"
      alt="netbanking"
    />
  ),
  atmcard: <FiCreditCard size={33} />,
  internationalcard: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/alepay_internationalcard.png"
      alt="alepay international card"
    />
  ),
  qrcode: (
    <img src="https://bccdn.azureedge.net/dev/online-payment-icons/alepay_qr.png" alt="alepay qr" />
  ),
  vietqr: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/alepay_vietqr.png"
      alt="alepay viet qr"
    />
  ),
  ideal: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/ideal.png" alt="ideal" />,
  upistripe: <img src="https://bccdn.azureedge.net/dev/online-payment-icons/upi.png" alt="upi" />,
  paybybank: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/net_banking.svg"
      alt="pay by bank"
    />
  ),
  cybersource: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/cybersource_logo.png"
      alt="cybersource"
    />
  ),
  promptpay: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/promptpay_logo.jpg"
      alt="promptpay"
    />
  ),
  fiuubanktransfers: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/bank-transfer.svg"
      alt="fiuubanktransfers"
    />
  ),
  syncbanktransfer: (
    <img
      src="https://bccdn.azureedge.net/dev/online-payment-icons/bank-transfer.svg"
      alt="syncbanktransfer"
    />
  )
};

import { createAppSettingsContext } from '@britishcouncil/react-common';

export interface Settings {
  settings: AppSettings;
  featureManagement: FeatureFlag
}

export interface AppSettings {
  version: string;
  environment: string;
  release: string;
  sentry: SentrySettings;
  appInsights: ApplicationInisghtsSettings;
  clarity: ClaritySettings;
}

export interface SentrySettings {
  isEnabled: boolean;
  dsn: string;
  tunnel: string;
  tracesSampleRate: number;
}

export interface ClaritySettings {
  isEnabled: boolean;
  projectId: string;
}

export interface ApplicationInisghtsSettings {
  isEnabled: boolean;
  instrumentationKey: string;
}

export interface FeatureFlag {
  tppEnabled: boolean;
}

export const DEFAULT_APP_SETTINGS: Settings = {
  settings: {
    version: '0.0.0',
    environment: '',
    release: '',
    appInsights: {
      isEnabled: false,
      instrumentationKey: '',
    },
    sentry: {
      isEnabled: false,
      dsn: '',
      tracesSampleRate: 0,
      tunnel: '',
    },
    clarity: {
      isEnabled: false,
      projectId: '',
    },
  },
  featureManagement: {
    tppEnabled: true
  }
};

export const { AppSettingsContext, useAppSettings } = createAppSettingsContext<Settings>();
